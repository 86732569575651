import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import apiService from "./../services/api.service";
import { authHeader } from '../helpers/auth-header';

const SubscriptionForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const apiUrl = apiService.apiUrl;
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) return;

        setLoading(true);

        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.error(error);
            setLoading(false);
            return;
        }

        try {

            let header = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authHeader()
                }
            };
           
            const response = await axios.post(`${apiUrl}/api/subscribe`, {
                paymentMethod: paymentMethod.id,
            }, header);

            if (response.data.success) {
                alert('Subscription successful!');
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <CardElement />
            <button type="submit" disabled={!stripe || loading}>
                {loading ? 'Processing...' : 'Subscribe'}
            </button>
        </form>
    );
};

export default SubscriptionForm;
