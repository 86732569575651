import React, { Component } from "react";
import paymentImage from "../pages/images/payment-success.jpg";
import {
  FaCheckCircle, FaExclamationCircle,
} from "react-icons/fa";
import "font-awesome/css/font-awesome.min.css";
import "../components/asterisk.css";
import "./styles/PackageSubscription.css";
import { Helmet } from "react-helmet";
//import Header from "./../components/Header";
import apiService from "./../services/api.service";
import { authHeader } from "../helpers/auth-header";
import MainServices from "../services/main.service";
import {
  Col,
  Row,
  Button,
} from "reactstrap";
import "./styles/accounts.css";
import { encrypt_url } from "./../helpers/encrypt-url";
import { ToastsStore } from "react-toasts";
import axios from "axios";
import loaderImg from "./images/loader.gif";
 

const apiUrl = apiService.apiUrl;
class PaymentSuccess extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem("user");

    this.state = {
      userObj: JSON.parse(user),
      userPackage: "",
      allPackages: "",
      selectPackageView: false,
      selectedPackage: "",
      encryptedString: encrypt_url(),
      paymentSession: "",
      paymentMessage:""
    };
  }

  componentDidMount() {
    if(!localStorage.getItem('user')){
      //this.props.history.push('/login')
      //return false;
    }
    const query = new URLSearchParams(this.props.location.search);
    const session_id = query.get("session_id");

    let header = {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    };

    let payload = {
      id: session_id,
      session_token: this.state.encryptedString,
      session_type: "expired",
    };

    axios
      .post(`${apiUrl}/api/package/payment/session-success`, payload, header)
      .then(
        (response) => {
          console.log("success:::::::", response.data);
          if (response.data.status === "success") {
            console.log("success", response);
            localStorage.setItem("twofa", "yes");
            MainServices.removeStore('trial_process')
            localStorage.setItem("package_info", response.data.package_info);
              this.setState({
                paymentMessage:"Payment successfully!"
              })
          }
        },
        (error) => {
            const errorMsg = error.response.data
            console.log("errors::::::", errorMsg);
            if(errorMsg.payment_charging && errorMsg.payment_charging.message){
              this.setState({
                paymentMessage:errorMsg.payment_charging.message
              })
            }else if(errorMsg.status==="error" && errorMsg.code)
            this.setState({
              paymentMessage:errorMsg.message
            })
            ToastsStore.error(errorMsg.message);
            localStorage.removeItem("twofa");
            return false;
        }
      );
  }

  componentWillUnmount() {
    // this.mounted = false;
  }

  handleBackBtn = (e) => {
    this.props.history.push(`/dashboard/${this.state.encryptedString}`);
    return false
  };

  bodyPackageContent = () => {
    return (
      <div>
        <Row className="mr-3">
          <Col sm={2}></Col>
          <Col sm={8}>
            <div className="card">
              <div className="card-header">Package Payment</div>
              <div className="card-body">
                {/* <h1 className="text-center text-success" style={{fontSize:'6rem'}}>
                  <FaCheckCircle />
                </h1> */}
                <Row>
                  <Col sm={6} className="text-center mt-4 text-success">
                    {
                      this.state.paymentMessage===""?(
                        <img className="loader-img" alt="loaderImg" src={loaderImg} />
                      ):("")
                    }
                    {this.state.paymentMessage==="Payment successfully!"?(<h1 className="text-center mt-4 text-success">
                      <FaCheckCircle />
                    </h1>):("")}

                    {this.state.paymentMessage==="Your card has insufficient funds."?(<h1 className="text-center mt-4 text-danger">
                      <FaExclamationCircle />
                    </h1>):("")}
                    
                    <h3 className={`mt-5 ${this.state.paymentMessage==="Payment successfully!"?"text-success":"text-danger"}`}>{this.state.paymentMessage}</h3>
                    {/* <p className=" text-muted mt-3">It is a long established fact that a reader will be distracted by 
                        the readable content of a page when looking at its layout. The point 
                        of using Lorem Ipsum is that it has a more-or-less normal distribution 
                        of letters, as opposed to using 'Content here, content here',</p> */}
                    <Button
                      className="btn btn-warning mt-4"
                      onClick={this.handleBackBtn}
                    >
                      Back to Dashboard
                    </Button>
                  </Col>

                  <Col sm={6} className="text-center">
                    <img
                      src={paymentImage}
                      alt="Payment Success"
                      width="99%"
                      height="auto"
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col sm={5}></Col>
        </Row>
      </div>
    );
  };

  render() {
    return (
      <div className="account-container package-subscription">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - Payment Success</title>
        </Helmet>
        {/* <Header {...this.props} /> */}
        <div>
          <Row className=" mt-4">
            <Col sm="2" className="account-name"></Col>
            <Col sm="8" className="account-name">
              <h1 className="account-header mt-5 pt-1 ml-5">Payment Status</h1>
              {/* <p>
                Nunc vel varius tortor. Quisque turpis enim, iaculis id nisi
                quis, consectetur cursus velit. Vivamus in tortor vehicula.
              </p> */}
            </Col>
          </Row>
        </div>

        <div className="contain-body">
          <Row>
            <Col sm={1}>{/*this.showSideMenu()*/}</Col>
            <Col sm={10}>{this.bodyPackageContent()}</Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default PaymentSuccess;
