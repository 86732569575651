import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_9muBAZnjHeFvBwxhQO8k145F00GXiohNqD');

const StripeProvider = ({ children }) => (
    <Elements stripe={stripePromise}>{children}</Elements>
);

export default StripeProvider;
