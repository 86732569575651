import React, { Component, useRef } from "react";
import Header from "../components/Header";
import { ToastsStore } from "react-toasts";
import "./styles/editprofile.css";
import "../components/asterisk.css";
import { Helmet } from "react-helmet";
import loaderImg from "./images/loader.gif";
import package_icon from "./images/starter.svg";
import subscription_icon from "./images/subscription.svg";
import unsubscription_icon from "./images/unsubscribe.svg";
import arrowUpRight from "./images/arrow-up-right.svg";
import arrowUpRightBK from "./images/arrow-up-right-bk.svg";
import camera from "./images/camera.svg";
import { FaEyeSlash, FaEye, FaCaretDown, FaUserTimes, FaUserCheck } from "react-icons/fa";
import PackagesServices from "./../services/packages.service";
import removeProfilePhoto from "../pages/images/delete-user.svg";
// import { PhoneInput } from 'react-international-phone';
// import 'react-international-phone/style.css';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import 'flag-icons/css/flag-icons.min.css';
import LeftSidebar from "./../components/LeftSidebar";
import { Form, FormGroup, Input, Label, Row, Col, Button, Modal, ModalBody, ModalFooter, ModalHeader, CustomInput, Media } from "reactstrap";
import { userService } from "../services/user.service";
import { Link } from "react-router-dom";
import { encrypt_url } from "../helpers/encrypt-url";
import profilePhoto from "../pages/images/dp.png";
import iconUserInfoEdit from "../pages/images/user_info_edit.svg";


import zxcvbn from "zxcvbn";

class EditUserProfile extends Component {
  constructor(props) {
    super(props);
    let user = localStorage.getItem("user");
    let userObj = JSON.parse(user);
    this.fileInputRef = React.createRef();
    this.fileUploadRef = React.createRef();
    this.state = {
      userObj: userObj,
      showPlan: false,
      showPayment: false,
      loading: false,
      userName: "",
      email: "",
      additional_email: "",
      currentPass: "",
      newPass: "",
      confirmPass: "",
      passwordScore: "",
      errorPassword: [],
      subPlan: 0,
      selectPackage: 0,
      passValue: "password",
      newPassValue: "password",
      errors: [],
      btnActive: false,
      userList: {
        id: userObj.data.user_id,
        userName: userObj.data.user_name,
        email: userObj.data.data.email,
        additional_email: userObj.data.data.additional_email,
        mobile: userObj.data.data.mobile,
        currentPass: "",
        newPass: "",
        confirmPass: "",
        subPlan: "",
        selPackageTitle: "",
      },
      editId: 0,
      userType: localStorage.getItem("user_type"),
      packageList: [],
      pay_method: "",
      card_number: "",
      expire_date: "",
      security_code: "",
      profile_image: "",
      selectProfileImage: "",
      loaderProfileImage: false,
      package_info: "",
      encryptedString: encrypt_url(),
      updateProfileSpin: false,
      modalUnsubscription: false,
      user_package_status: "",
      editUserInfo: false,
      strUserInfo: userObj.data.data.user_info,
      unsubscribeSpin:false
    };
  }

  componentDidMount() {

    if (localStorage.getItem("user_type") === "primary") {
      this.getPackageByUser();
    }
    let user = localStorage.getItem("user");
    const profile_image = JSON.parse(user).data.profile_image
    if (profile_image && profile_image.substr(0, 17) == "/static/media/dp.") {
      this.setState({
        profile_image: profilePhoto,
      });

    } else if (profile_image) {
      this.setState({
        profile_image: profile_image,
      });
    } else {
      this.setState({
        profile_image: profilePhoto,
      });
    }


    this.getPackages();
  }

  getPackages = () => {
    PackagesServices.getPackages(this).then(
      (response) => {
        if (response.data.status === "success") {
          this.setState({
            packageList: response.data.data,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  getPackageByUser = () => {
    PackagesServices.getPackageByUser(this).then(
      (response) => {
        if (response.data.status === "success") {
          const package_info = response.data.data;
          this.setState({
            userList: {
              ...this.state.userList,
              subPlan: package_info.id,
              selPackageTitle: package_info.package,
            },
            package_info: response.data,
            user_package_status: response.data.user_package.subscription_status
          });

        }
      },
      (error) => {
        console.log(error.response.data.line);
        ToastsStore.error(
          "Sorry, package info error, " + error.response.data.message
        );
      }
    );
  };

  showPlanOption = () => {
    this.setState((prevState) => ({
      showPlan: !prevState.showPlan,
    }));
  };

  showPaymentMethod = () => {
    this.setState((prevState) => ({
      showPayment: !prevState.showPayment,
    }));
  };

  handleOptionChange = (packPlan) => {
    let pkgStr = packPlan.target.value.split("=");
    this.setState({
      userList: {
        ...this.state.userList,
        subPlan: pkgStr[0],
        selPackageTitle: pkgStr[1],
      },
      btnActive: true,
    });
  };

  handlePaymentChange = (payment) => {
    let { name, value } = payment.target;
    this.setState({
      [name]: value,
    });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.setState({ loadingPkg: true });
    let data = {
      sub_plan: this.state.userList.subPlan,
    };
    PackagesServices.savePackageInfo(this, data).then(
      (response) => {
        if (response.data.status === "success") {
          let userTmp = this.state.userObj;
          userTmp.data.sub_plan = response.data.sub_plan;
          this.setState({
            userObj: userTmp,
          });

          localStorage.setItem("user", JSON.stringify(this.state.userObj));
          if (response.data.package_info.price === 0) {
            this.setState({ loadingPkg: false });
          } else {
            this.setState((prevState) => ({
              showPayment: !prevState.showPayment,
              loadingPkg: false,
              showPlan: false,
            }));
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  handleSubmitPayment = (e) => {
    e.preventDefault();
    this.setState({ loadingPkg: true });
    let { pay_method, card_number, expire_date, security_code } = this.state;
    if (!pay_method || !card_number || !expire_date || !security_code) {
      ToastsStore.error("Please complete the card information!");
      return false;
    }
    let data = {
      card_type: pay_method,
      card_number: card_number,
      card_expire: expire_date,
      card_security_code: security_code,
    };
    PackagesServices.savePaymentInfo(this, data).then(
      (response) => {
        if (response.data.status === "success") {
          console.log(response);
          ToastsStore.success("Payment info hasn been saved successfully!");
          this.setState((prevState) => ({
            showPayment: !prevState.showPayment,
            loadingPkg: false,
          }));
        }
      },
      (error) => {
        console.log(error);
        ToastsStore.error("Payment info hasn't been saved");
      }
    );
    //this.setState({ showPlan: false });
  };

  changePackage = () => {
    this.props.history.push(
      `/packagesubscriptionprofile/${this.state.encryptedString}`
    );
  };

  changePackageUnSubscribe = e => {
    e.preventDefault();
    this.setState({ loadingPkg: true, unsubscribeSpin : true });

    PackagesServices.unsubscribePackage(this).then(
      (response) => {
        if (response.data.status === "success") {
 
          this.setState((prevState) => ({
            modalUnsubscription: !prevState.modalUnsubscription,
            user_package: response.data.user_package,
            user_package_status: response.data.user_package.subscription_status
          }));
          ToastsStore.success("Payment unsubscribe has been updated successfully!");
          //window.location.reload();
        }else if (response.data.status === "error") {
 
          this.setState((prevState) => ({
            modalUnsubscription: !prevState.modalUnsubscription,
            user_package: response.data.user_package,
            user_package_status: response.data.user_package.subscription_status
          }));
          ToastsStore.success("Payment unsubscribe has been updated successfully!");
          //window.location.reload();
        }

      },
      (error) => {
        console.log('error', error);
        
        this.setState((prevState) => ({
          modalUnsubscription: !prevState.modalUnsubscription,
          user_package_status: 0
        }));
        ToastsStore.warning("Your subscription already unsubscribed or you haven't subscribe any package!");
      }
    );
    //this.setState({ showPlan: false });
  }

  showSubscription = () => {
    if (localStorage.getItem("user_type") === "bn") {
    } else if (localStorage.getItem("user_type") === "primary") {
      return (
        <Col md={6}>

          <Label className="lbl-prof">
            <img src={subscription_icon} style={{ width: '25px', height: '25px' }} /> &nbsp;
            Subscription
          </Label>
          <span className="btn">

            <img src={package_icon} style={{ width: '30px', height: '30px' }} />
            {this.state.userList.selPackageTitle}
          </span>
          {/* <span className="change-plan btn btn-outline-secondary" onClick={this.changePackage}>
            Change <FaCaretDown />
          </span> */}
        </Col>
      );
    }
  };

  showUnSubscription = () => {
    if (localStorage.getItem("user_type") === "bn") {
    } else if (localStorage.getItem("user_type") === "primary") {
      if (this.state.user_package_status == 1) {
        return (
          <Col md={6}>
            <FormGroup>
              <Label className="mb-3"><b>Unsubscription</b></Label><br />
              <a href="#" className=" btn bg-warning" onClick={this.toggleUnsubscription}>
                <img src={unsubscription_icon} style={{ height: '20px', width: '20px' }} /> Unsubscribe
              </a>
            </FormGroup>
          </Col>
        );
      } else {
        return (
          <Col md={6}>
            <FormGroup>
              <Label className="mb-3"><b>Subscription</b></Label><br />
              <a className=" btn bg-success" onClick={this.changePackage}>
                <FaUserCheck /> Subscribe
              </a>
            </FormGroup>
          </Col>
        );
      }

    }
  };

  profileImageUpdate = (e) => {
    let selectProfileImage = e.target.files[0];
    if (selectProfileImage) {
      this.setState({
        profile_image: URL.createObjectURL(selectProfileImage),
        selectProfileImage: selectProfileImage,
      });

      this.setState({ loaderProfileImage: true });
      const selectImgFile = selectProfileImage
      const getCurrentState = this
      setTimeout(function () {
        userService.uploadProfileImage(selectImgFile, getCurrentState);
      }, 1000)

    }
  };

  removeProfileImage = () => {

    this.setState({
      profile_image: profilePhoto,
      selectProfileImage: profilePhoto,
    });

    userService.removeProfileImage(this.state.selectProfileImage, this);
  };

  toggleUnsubscription = (e) => {
    e.preventDefault()
    //changePackageUnSubscribe
    this.setState((prevState) => ({
      modalUnsubscription: !prevState.modalUnsubscription,
    }));
  };


  prevideProfileImage = () => {

    const fileInput = document.getElementById('fileInput');
    const previewImage = document.getElementById('previewImage');

    // Trigger file input click when the image is clicked
    previewImage.addEventListener('click', () => {
      fileInput.click();
    });

    // Handle file selection and display the selected image
    fileInput.addEventListener('change', () => {
      const file = fileInput.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          previewImage.src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    });

  }

  handleImageClick = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
    //if (this.fileUploadRef.current) {
    //this.fileUploadRef.current.focus();
    //this.fileUploadRef.current.submit();
    //}
  };


  editUserInfo = () => {
    this.setState({ editUserInfo: true })
  }

  saveEditUserInfo = () => {
    this.setState({ editUserInfo: false })
    userService.editUserInfo(this, this.state.strUserInfo);
  }

  changeEditUserInfo = (e) => {
    this.setState({ strUserInfo: e.target.value })
  }

  showProfileList = () => {
    let itemList = [];
    itemList = (
      <div>
        <Form method="post" onSubmit={this.uploadProfileImage} >

          <div className="row">
            <div className="col-md-3 profile-img-div">
              <div className="col-md-12 border p-4 profile-image-holder" style={{ borderRadius: '15px', height: '200px' }}>
                <div className="row">
                  <div className="col-md-12">
                    <Media
                      object
                      src={this.state.profile_image}
                      alt="dp"
                      className="img-thumbnail rounded-circle profileImageMain "
                      onClick={this.toggleProfilePhoto}
                      style={{ height: '125px', width: '125px' }}
                    />

                    {this.state.loaderProfileImage ? (<img src={loaderImg} height="25" width="25" alt="this heart" />) :
                      (
                        <div className="row" style={{ marginTop: '-30px', width: '160px' }}>
                          <div className="col-6">
                            <img src={camera} className="profile-img-btn-bk" onClick={this.handleImageClick} />
                          </div>
                          <div className="col">
                            <svg width="30" height="30" onClick={this.removeProfileImage} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                              <circle cx="13" cy="13" r="10" stroke="#F6DA20" strokeWidth="1" />
                              <line x1="9" y1="9" x2="17" y2="17" stroke="#000" strokeWidth="1" />
                              <line x1="9" y1="17" x2="17" y2="9" stroke="#000" strokeWidth="1" />
                            </svg>
                          </div>
                        </div>)}

                    <div className="text-center text-muted w-100 text-small" style={{ width: '100%' }}>
                      Profile image size: 300x300
                    </div>
                    <Row form className="mt-5">
                      <Col lg={12}>
                        <div className="text-center mt-4 ">

                          <input
                            id="txtProfileImage"
                            ref={this.fileInputRef}
                            accept="image/*"
                            type="file"
                            className="btn-info btn-sm btn-block"
                            onChange={this.profileImageUpdate}
                          />
                          <Button className="btn-success btn-sm mt-2 btn-block" ref={this.fileUploadRef}>
                            {this.state.loaderProfileImage && (
                              <img src={loaderImg} height="25" width="25" alt="this heart" />
                            )}{" "}
                            Upload
                          </Button>

                          <Button className="btn-danger btn-sm mt-2 btn-block" onClick={this.removeProfileImage}>
                            Remove Image
                          </Button>


                        </div>
                      </Col>
                    </Row>
                  </div>

                </div>
              </div>
            </div>{/*** end col-md-6 **/}

            <div className="col profile-fname-div">
              <div className=" border p-4" style={{ borderRadius: '15px', height: '200px' }}>
                <h3 className="mb-0">{this.state.userList.userName}</h3>
                <small >{this.state.userList.email}</small>
                <p className="mt-2 text-right" style={{ display: this.state.editUserInfo ? '' : 'none' }} >
                  <textarea className="form-control" onChange={this.changeEditUserInfo} value={this.state.strUserInfo} id="user_info_edit" name="user_info_edit">{this.state.strUserInfo}</textarea>
                  <Button type="button" onClick={this.saveEditUserInfo} className="mt-2">SAVE</Button>
                </p>
                <p className="mt-2" style={{ display: this.state.editUserInfo ? 'none' : '' }} >
                  {this.state.strUserInfo}
                  <span className="ml-3"><img src={iconUserInfoEdit} onClick={this.editUserInfo} style={{ height: '20px', width: '20px' }} /></span>
                </p>
              </div>
            </div>
          </div>

          <Row>
            <hr />
          </Row>
        </Form>
        <Form method="post" onSubmit={this.updateProfile}>
          <div className="row mb-5 hide">
            <div className="col-md-6">
              <div className="mt-4">
                <div className="lbl-prof">Address</div>
                <input type="text" className="form-control" />
              </div>

            </div>
            <div className="col-md-6">
              <div className="mt-4">
                <div className="lbl-prof">Postal/Zip Code</div>
                <input type="text" className="form-control" />
              </div>

            </div>
          </div>{/*** end row ****/}

          <div className="row mb-4">
            <div className="col-md-6">
              <div className="lbl-prof">Name</div>
              <Input
                type="text"
                name="user_name"
                id="user_name"
                required
                value={this.state.userList.userName}
                onChange={this.onChangeName}
                className="form-control"
              />
              <div className="errormessage">{this.state.errors.userName}</div>
            </div>


            <div className="col-md-6">
              <div className="lbl-prof">Email Address (Additional email)</div>
              <Input
                type="email"
                name="additional_email"
                id="additional_email"
                defaultValue={this.state.userList.additional_email}
                onChange={this.onChangeEmail}
                className="form-control"
              />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-6">
              <div className="lbl-prof control-label required">Current Password</div>
              <Input
                type={this.state.passValue}
                name="current_pass"
                id="current_pass"
                placeholder="Current password"
                required
                value={this.state.userList.currentPass}
                onChange={this.onChangeCurrentPass}
                className="form-control"
                autoComplete="new-password"
              />
              <div className="errormessage"> {this.state.errors.currentPass}  </div>
            </div>
            <div className="col-md-6">
              <div className="lbl-prof">Mobile Number</div>
              <div className=" ">
                <PhoneInput name="mobile" id="mobile" international countrySelectProps={{ unicodeFlags: true }} placeholder="Enter mobile number" className="form-control overflow-hidden" value={this.state.userList.mobile} onChange={this.onChangeMobile} />
              </div>
              <div className="help-block" style={{ width: "100%", marginTop: "15px" }} > {this.state.errors.mobile}  </div>

            </div>



          </div>

          {/*** end row ***/}

          <div className="row mobile-number-div">
            <div className="col-md-6">
              <div className="lbl-prof control-label required">New Password</div>
              <Input
                type={this.state.newPassValue}
                name="new_pass"
                id="new_pass"
                className="input-update"
                placeholder="Enter new password"
                value={this.state.userList.newPass}
                onChange={this.onChangeNewPass}
              />
              <span
                className="profile-passIcon"
                onClick={this.eyeIcon.bind(this, "newPassword")}
              >
                {this.state.newPassValue === "password" ? (
                  <FaEyeSlash />
                ) : (
                  <FaEye />
                )}
                {/* :{this.state.passwordScore} */}
              </span>
              <div className="errormessage">{this.state.errors.newPass}</div>
              <div className="errormessage">
                <ul>
                  {this.state.errorPassword.map((err, index) => (
                    <li key={index}>{err}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="lbl-prof control-label required">Confirm Password</div>
              <Input
                type="password"
                name="confirm_pass"
                id="confirm_pass"
                placeholder="Confirm new password"
                value={this.state.userList.confirmPass}
                onChange={this.onChangeConfirmPass}
              />
              <div className="errormessage">
                {this.state.errors.confirmPass}
              </div>
            </div>


          </div>{/*** end row ***/}




          <Row className="mt-5">
            {this.showSubscription()}
            {this.showUnSubscription()}
          </Row>
          <hr className="mt-3" />
          <Row>
            <Col lg={6}></Col>
            <Col lg={12} sm={12}>
              <div className="btn-updt text-right">
                <p className="help-profile">{this.state.errorMessage}</p>
                {this.state.loading && (
                  <img className="loader-img" alt="loaderImg" src={loaderImg} />
                )}
                <Button
                  type="submit"
                  className="btn bg-thisheart"
                  disabled={this.state.btnActive === false}
                >
                  {this.state.updateProfileSpin && (
                    <i className="fa fa-circle-o-notch fa-spin"></i>
                  )}
                  &nbsp; Update Information
                  {this.state.btnActive === false ? (<img src={arrowUpRight} className="ml-2" />) : (<img src={arrowUpRightBK} className="ml-2" />)}
                </Button>
                {
                  (localStorage.getItem("user_type") === "bn") ?
                    (<Link to={`/beneficiarylanding/${this.state.encryptedString}`}>
                      <Button className="btn btn-secondary ml-3">Cancel</Button>
                    </Link>) : (<Link to={`/dashboard/${this.state.encryptedString}`}>
                      <Button className="btn btn-secondary ml-3">Cancel</Button>
                    </Link>)
                }

              </div>
            </Col>
          </Row>
        </Form>
      </div>
    );
    return itemList;
  };

  validUserName = () => {
    let userName = this.state.userName;
    let errors = [];
    let formIsValid = true;
    if (userName.length === 0) {
      formIsValid = false;
      errors["userName"] = "*User name can't be empty";
    }
    this.setState({ errors: errors });
    console.log("user name is ::::", userName);
    return formIsValid;
  };

  validEmail = () => {
    let email = this.state.email;
    let errors = [];
    let formIsValid = true;
    if (email.length === 0) {
      formIsValid = false;
      errors["email"] = "*Cannot Be Empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  validCurrentPassword = () => {
    let currentPass = this.state.userList.currentPass;
    let errors = [];
    let formIsValid = true;
    if (currentPass.length < 1) {
      formIsValid = false;
      errors["currentPass"] = "*Cannot Be Empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };


  validNewPassword = () => {
    let errors = [];
    let currentPass = this.state.userList.currentPass;
    let newPass = this.state.userList.newPass;
    let formIsValid = true;

    if (newPass === currentPass) {
      formIsValid = false;
      errors["newPass"] = "*New password can't be same with current password!";
    }

    if (newPass.length < 1) {
      formIsValid = false;
      errors["newPass"] = "*Cannot Be Empty";
    }

    this.setState({ errors: errors });
    return formIsValid;
  };

  validConfirmPass = () => {
    let confirmPass = this.state.userList.confirmPass;

    let errors = [];
    let formIsValid = false;
    let newPass = this.state.userList.newPass;
    let currentPass = this.state.userList.currentPass;

    if (newPass == currentPass) {
      formIsValid = false;
      errors["newPass"] = "*New password can't be same with current password!";
    }

    if (confirmPass !== newPass) {
      formIsValid = false;
      errors["confirmPass"] = "*password does not match";
    } else {
      formIsValid = true;
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  onChangeName = (e) => {
    this.setState({
      userList: {
        ...this.state.userList,
        userName: e.target.value,
      },
      btnActive: true,
    });
  };

  onChangeEmail = (e) => {

    this.setState({
      userList: {
        ...this.state.userList,
        additional_email: e.target.value,
      },
      btnActive: true,
    });

    this.validEmail();
  };

  onChangeMobile = (e) => {
    this.setState({
      userList: {
        ...this.state.userList,
        mobile: e,
      },
      btnActive: true,
    });
    console.log("mobile: ", this.state.userList.mobile);
  };

  onChangeCurrentPass = (e) => {
    this.setState(
      {
        userList: {
          ...this.state.userList,
          currentPass: e.target.value,
        },
        btnActive: true,
      },
      () => {
        this.validCurrentPassword();
      }
    );

  };

  onChangeNewPass = (e) => {
    e.preventDefault()
    const { name, value } = e.target;
    this.setState({ [name]: value });
    const newPass = e.target.value;
    const { currentPass } = this.state.userList;

    if (name === "new_pass") {
      const passwordSuggent = zxcvbn(value);
      this.setState({
        passwordScore: passwordSuggent.score,
        errorPassword: passwordSuggent.feedback.suggestions,
      });
    }
    this.setState(
      {
        userList: {
          ...this.state.userList,
          newPass: e.target.value,
        },
        btnActive: true,
      },
      () => {
        this.validNewPassword();
      }
    );


  };

  onChangeConfirmPass = (e) => {
    e.preventDefault()
    this.setState({
      userList: {
        ...this.state.userList,
        confirmPass: e.target.value,
      },
      btnActive: true,
    });
  };

  uploadProfileImage = (e) => {
    e.preventDefault();
    this.setState({ loaderProfileImage: true });
    userService.uploadProfileImage(this.state.selectProfileImage, this);
  };

  updateProfile = (e) => {
    e.preventDefault();

    let userList = this.state.userList;
    let userName = document.getElementById("user_name").value;
    let additional_email = document.getElementById("additional_email").value;
    let currentPass = document.getElementById("current_pass").value;
    let newPass = document.getElementById("new_pass").value;

    let currentPassCheck = this.validCurrentPassword();
    let confirmPassCheck = this.validConfirmPass();

    if (newPass == currentPass) {
      return false;
    }

    if (!confirmPassCheck) {
      console.log("confirmPassCheck Password not matched");
      return false;
    }
    if (!currentPassCheck) {
      console.log("currentPassCheck Password not matched");
      return false;
    }


    if (newPass.length > 7 && this.state.passwordScore < 3) {
      let errors = [];
      errors["newPass"] = "*Cannot Be Empty";
      this.setState({ errors: errors });
      return false;
    }

    userList = {
      id: this.state.userList.id,
      user_name: userName,
      additional_email: additional_email,
      currentPass: currentPass,
      newPass: newPass,
      mobile: this.state.userList.mobile,
    };

    this.setState({ btnActive: true, updateProfileSpin: true });
    userService.editProfile(this, userList);
  };

  eyeIcon = (eyetype) => {
    switch (eyetype) {
      case "currentPassword":
        if (this.state.passValue === "password") {
          this.setState({ passValue: "text" });
        } else {
          this.setState({ passValue: "password" });
        }
        break;
      case "newPassword":
        if (this.state.newPassValue === "password") {
          this.setState({ newPassValue: "text" });
        } else {
          this.setState({ newPassValue: "password" });
        }
        break;

      default:
    }
  };

  render() {
    return (
      <div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin1" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">

        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart- Update Account</title>
        </Helmet>

        <Header {...this.props} profile_image={this.state.profile_image} />
        <LeftSidebar {...this.props} />
        <div className="page-wrapper" style={{ display: 'block' }}>
          <div className="container-fluid px-4">
            <div className="prof-head">
              <Row>
                <Col sm="12">
                  <div className="profile-name">
                    <h1>Account Information</h1>
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col md={8} sm={12} xs={12} className="tab-profile-div">
                  <div className="form-box">{this.showProfileList()}</div>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        {/******************************* Subscription plan Start ****************************/}

        <div className="modal-div">
          <Modal
            isOpen={this.state.showPlan}
            toggle={this.showPlanOption}
            className={this.props.className}
          >
            <ModalHeader toggle={this.showPlanOption}>
              Subscription Plan
            </ModalHeader>
            <ModalBody className="form-popup">
              <Form method="post" onSubmit={this.handleFormSubmit}>
                <FormGroup>
                  <Label>
                    <b>Select your desire plan</b>
                  </Label>
                  <div>
                    {this.state.packageList &&
                      this.state.packageList.map((packages, index) => {
                        return (
                          <CustomInput
                            type="radio"
                            key={index}
                            id={packages.id}
                            name="customRadio"
                            label={packages.package}
                            value={packages.id + "=" + packages.package}
                            onChange={this.handleOptionChange}
                            defaultChecked={
                              packages.id === this.state.userList.subPlan
                            }
                            required
                          />
                        );
                      })}
                  </div>
                  {this.state.loadingPkg && (
                    <img
                      src={loaderImg}
                      alt="loading"
                      height="40px"
                      width="auto"
                    />
                  )}
                </FormGroup>

                <Button
                  className="option-btnSave "
                  type="submit"
                  onClick={this.showPlanOption}
                >
                  Save
                </Button>
                <Button
                  className="option-btnCancel"
                  type="button"
                  onClick={this.showPlanOption}
                >
                  Cancel
                </Button>
              </Form>
            </ModalBody>
          </Modal>
        </div>
        {/* /****************************** Subscription plan option End ****************************/}

        {/******************************* Subscription payment method start Start ****************************/}

        <div className="modal-div">
          <Modal
            isOpen={this.state.showPayment}
            toggle={this.showPaymentMethod}
            className={this.props.className}
          >
            <ModalHeader toggle={this.showPaymentMethod}>
              Payment Method
            </ModalHeader>
            <ModalBody className="form-popup">
              <Form method="post" onSubmit={this.handleSubmitPayment}>
                <FormGroup>
                  <Label>
                    <b>Select Payment Method</b>
                  </Label>
                  <div>
                    <CustomInput
                      type="radio"
                      key="1"
                      id="p-1"
                      name="pay_method"
                      label="Master Card"
                      value="Master Card"
                      onChange={this.handlePaymentChange}
                      required
                    />

                    <CustomInput
                      type="radio"
                      key="2"
                      id="p-2"
                      name="pay_method"
                      label="Visa Card"
                      value="Visa Card"
                      onChange={this.handlePaymentChange}
                      required
                    />
                  </div>
                  {this.state.loadingPkg && (
                    <img
                      src={loaderImg}
                      alt="loading"
                      height="40px"
                      width="auto"
                    />
                  )}
                </FormGroup>
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup>
                      <Label className="lbl-prof">Card Number</Label>
                      <Input
                        type="number"
                        name="card_number"
                        id="card_number"
                        required
                        placeholder="Enter Card Number"
                        value={this.state.card_number}
                        onChange={this.handlePaymentChange}
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup>
                      <Label className="lbl-prof">Expire</Label>
                      <Input
                        type="month"
                        name="expire_date"
                        id="expire_date"
                        required
                        placeholder="Card Expiry Date"
                        value={this.state.expire_date}
                        onChange={this.handlePaymentChange}
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup>
                      <Label className="lbl-prof">Security Code</Label>
                      <Input
                        type="number"
                        name="security_code"
                        id="security_code"
                        required
                        placeholder="Security Code"
                        value={this.state.security_code}
                        onChange={this.handlePaymentChange}
                      />
                    </FormGroup>
                  </div>
                </div>

                <Button className="option-btnSave " type="submit">
                  Save
                </Button>
                <Button
                  className="option-btnCancel"
                  type="button"
                  onClick={this.showPaymentMethod}
                >
                  Cancel
                </Button>
              </Form>
            </ModalBody>
          </Modal>
        </div>
        {/* /****************************** Subscription payment method End ****************************/}

        {/* /****************************** Subscription Modal Start ****************************/}
        <div className="modal-div">
          <Modal
            isOpen={this.state.modalUnsubscription}
            toggle={this.toggleUnsubscription}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleUnsubscription}>
              {this.state.user_package_status === 0 ? <b>Subscribe</b> : <b>Unsubscribe</b>}
            </ModalHeader>
            <ModalBody className="logout-acc">
              <p>Are you sure you want to {this.state.user_package_status === 0 ? <b>Subscribe</b> : <b>Unsubscribe</b>} the current package finally?</p>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" type="button" onClick={this.changePackageUnSubscribe}>
                { this.state.unsubscribeSpin && (<i className="fa fa-circle-o-notch fa-spin mx-1"></i>)}
                {this.state.user_package_status == 0 ? <b> Subscribe</b> : <b> Unsubscribe</b>}
              </Button>
              <Button
                style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
                type="button"
                onClick={this.toggleUnsubscription}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
 
      </div>
    );
  }
}
export default EditUserProfile;
