import { authHeader } from '../helpers/auth-header';
import axios from 'axios';
import apiService from './api.service';
import * as Codebird from "../services/twitter.service";
import { ToastsStore } from "react-toasts";

const socialService = {
    statusChangeCallback,
    checkFacebookStatesTmp,
    getPhotoContent,
    viewPhotos,
    fetchInstagram,
    fetchFacebook,
    firstInitFB,
    getFbPhoto,
    fetchTwitter,
    viewPhotos
};

const apiUrl = apiService.apiUrl;

function firstInitFB(self) {
    self.setState({ loadingSocialData: true });
    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));


    window.fbAsyncInit = function () {
        this.FB.init({
            appId: '626068786526725',//Shourov
            // appId: '1096844991745433',//Shourov
            // appId: '2797533413812321',//Shourov
            // appId: '349211774629753',//Shourov
            // appId            : '411427716243950',//daniel
            //appId            : '979913875684349',//shahin
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v4.0',
            cookie: true
        });
        localStorage.setItem("fb_access_token", '');
        localStorage.setItem("fb_user_id", '');
        self.setState({ loadingSocialData: false });
        this.FB.getLoginStatus(response => {
        });

    };

}


function fetchFacebook(self) {
    localStorage.setItem("fb_access_token", '');
    localStorage.setItem("fb_user_id", '');
    this.FB.getLoginStatus(response => {

        if (response.status === 'connected') {
            let accessToken = response.authResponse.accessToken;
            let user_id = response.authResponse.userID;
            localStorage.setItem("fb_access_token", accessToken);
            localStorage.setItem("fb_user_id", user_id);
            getPhotoContent(self);
        } else {

            this.FB.login(response => {
                self.setState({ loadingSocialData: false });
                if (response.status === 'connected') {
                    let accessToken = response.authResponse.accessToken;
                    let user_id = response.authResponse.userID;
                    localStorage.setItem("fb_access_token", accessToken);
                    localStorage.setItem("fb_user_id", user_id);
                    getPhotoContent(self);
                }
            });
        }
    });
}

function statusChangeCallback(response) {
}

function checkFacebookStatesTmp() {
    this.FB.getLoginStatus(response => {
        if (response.status === 'connected') {
        } else {

        }
    });
}

function getPhotoContent(selfs, access_token) {
    let fb_access_token = access_token;
    if (fb_access_token) {
        axios.get("https://graph.facebook.com/me/videos/uploaded?fields=source,description", {
            params: {
                access_token: fb_access_token
            }
        })
            .then(response => {
                console.log("video_response::", response.data); // Log the entire video response data to console
                let videoList = response.data.data.map(video => video.source);
                console.log("Video List:", videoList); // Log all video sources to console
                let header = {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: authHeader()
                    }
                };
                let data = JSON.stringify({
                    user_id: '',
                    title: '',
                    social_type: 'facebook',
                    file_type: 'video',
                    videoList: videoList
                });

                axios
                    .post(`${apiUrl}/api/socialStorePhotos`, data, header)
                    .then(response => {
                        console.log("Videos stored successfully.", response);
                    })
                    .catch(error => {
                        console.log("Error storing videos: ", error);
                    });
            })
            .catch(error => {
                console.log("error::", error)
            })

        axios.get("https://graph.facebook.com/me/albums", {
            params: {
                access_token: fb_access_token
            }
        })
            .then(response => {
                let photoList = [];
                if (response.data.data) {
                    response.data.data.forEach((album) => {
                        axios.get(`https://graph.facebook.com/${album.id}/photos`, {
                            params: {
                                access_token: fb_access_token,
                                fields: 'images'
                            }
                        })
                            .then(photoResponse => {
                                console.log("photoResponse::", photoResponse)
                                photoResponse.data.data.forEach((photo) => {
                                    if (photo.images.length > 0) {
                                        photoList.push(photo.images[0].source);
                                    }
                                });
                            })
                            .catch(error => {
                                console.log('Error fetching photos: ', error);
                            });
                    });

                    // Wait for all promises to resolve before sending the photo list
                    Promise.all(photoList.map(photo => axios.get(photo)))
                        .then(() => {
                            let header = {
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: authHeader()
                                }
                            };
                            let data = JSON.stringify({
                                user_id: '',
                                title: '',
                                social_type: 'facebook',
                                file_type: 'image',
                                imageList: photoList
                            });

                            axios
                                .post(`${apiUrl}/api/socialStorePhotos`, data, header)
                                .then(response => {
                                    selfs.setState({ socialFBImagesList: photoList });
                                    viewPhotos(selfs);
                                }, error => {
                                    selfs.setState({ loadingSocialData: false });
                                });
                        })
                        .catch(error => {
                            console.log('Error storing photos: ', error);
                        });
                }
            }, error => {
                console.log('Facebook API error: ', error);
            });


    }
}


function getFbPhoto(selfs, response, fbsocialrequest, tag) {
    console.log("responses::", response)
    if (response) {
        if (response.posts && response.posts.data) {
            let postList = response.posts.data ? response.posts.data : '';

            //start hastag  ************************
            console.log("postList::", postList)

            let videoList_details = postList.filter(post => post.type === 'video');
            let photoList_details = postList.filter(post => post.type === 'photo');
            console.log("videoList_details::", videoList_details)
            console.log("photoList_details::", photoList_details)

            let header = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authHeader()
                }
            };

            if (fbsocialrequest === 'video') {
                if (tag) {
                    console.log("video tag:", tag);
                    let filteredVideos = videoList_details.filter(video => video.message ? video.message.toLowerCase().split(' ').some(word => word.includes(tag.toLowerCase())) : true);
                    if (filteredVideos.length > 0) {
                        let videoData = JSON.stringify({
                            user_id: '',
                            title: '',
                            social_type: 'facebook',
                            file_type: 'video',
                            dataList: filteredVideos
                        });
                        console.log("videoDataIG_tag", videoData)

                        axios
                            .post(`${apiUrl}/api/socialStorePhotos`, videoData, header)
                            .then(response => {
                                console.log('socialStoreVideos: ', response);
                                viewPhotos(selfs);
                            }, error => {
                                console.log('Social videos stores err: ', error);
                                selfs.setState({ loadingSocialData: false });
                            })
                    } else {
                        ToastsStore.warning(`Tag ${tag} not found in videos.`);
                        selfs.setState({ loadingSocialData: false });
                    }
                } else {
                    let videoData = JSON.stringify({
                        user_id: '',
                        title: '',
                        social_type: 'facebook',
                        file_type: 'video',
                        dataList: videoList_details
                    });
                    console.log("videoDataIG", videoData)

                    axios
                        .post(`${apiUrl}/api/socialStorePhotos`, videoData, header)
                        .then(response => {
                            console.log('socialStoreVideos: ', response);
                            viewPhotos(selfs);
                        }, error => {
                            console.log('Social videos stores err: ', error);
                            selfs.setState({ loadingSocialData: false });
                        })
                }
            } else if (fbsocialrequest === 'photo') {
                if (tag) {
                    console.log("photo tag:", tag);
                    let filteredPhotos = photoList_details.filter(photo => photo.message ? photo.message.toLowerCase().split(' ').some(word => word.includes(tag.toLowerCase())) : true);
                    if (filteredPhotos.length > 0) {
                        let photoData = JSON.stringify({
                            user_id: '',
                            title: '',
                            social_type: 'facebook',
                            file_type: 'image',
                            dataList: filteredPhotos
                        });
                        console.log("photoDatafb_tag", photoData)

                        axios
                            .post(`${apiUrl}/api/socialStorePhotos`, photoData, header)
                            .then(response => {
                                console.log('socialStorePhotos: ', response);
                                viewPhotos(selfs);
                            }, error => {
                                console.log('Social photos stores err: ', error);
                                selfs.setState({ loadingSocialData: false });
                            })
                    } else {
                        ToastsStore.warning(`Tag ${tag} not found in photos.`);
                        selfs.setState({ loadingSocialData: false });
                    }
                } else {
                    let photoData = JSON.stringify({
                        user_id: '',
                        title: '',
                        social_type: 'facebook',
                        file_type: 'image',
                        dataList: photoList_details
                    });
                    console.log("photoDatafb", photoData)

                    axios
                        .post(`${apiUrl}/api/socialStorePhotos`, photoData, header)
                        .then(response => {
                            console.log('socialStorePhotos: ', response);
                            viewPhotos(selfs);
                        }, error => {
                            console.log('Social photos stores err: ', error);
                            selfs.setState({ loadingSocialData: false });
                        })
                }
            }

            if (videoList_details.length === 0 && photoList_details.length === 0) {
                viewPhotos(selfs);
            }
        }

    }
}

// function getFbPhoto(selfs, response, fbsocialrequest, tag) {
//     console.log("responses::", response)
//     if (response) {
//         if (response.posts && response.posts.data) {
//             let postList = response.posts.data ? response.posts.data : '';

//             //start hastag  ************************
//             console.log("postList::", postList)

//             let videoList_details = postList.filter(post => post.type === 'video');
//             let photoList_details = postList.filter(post => post.type === 'photo');
//             console.log("videoList_details::", videoList_details)
//             console.log("photoList_details::", photoList_details)

//             // let videoList = [];
//             // let photoList = [];

//             // videoList_details.forEach(video => {
//             //     videoList.push({ source: video.source || '', full_picture: video.full_picture });
//             // });
//             // photoList_details.forEach(photo => {
//             //     photoList.push({ source: photo.source || '', full_picture: photo.full_picture });
//             // });

//             // console.log("videoList::", videoList);
//             // console.log("photoList::", photoList);

//             let header = {
//                 headers: {
//                     'Content-Type': 'application/json',
//                     Authorization: authHeader()
//                 }
//             };

//             if (videoList_details.length > 0) {
//                 let videoData = JSON.stringify({
//                     user_id: '',
//                     title: '',
//                     social_type: 'facebook',
//                     file_type: 'video',
//                     dataList: videoList_details
//                 });

//                 axios
//                     .post(`${apiUrl}/api/socialStorePhotos`, videoData, header)
//                     .then(response => {
//                         console.log('socialStoreVideos: ', response);
//                         viewPhotos(selfs);
//                     }, error => {
//                         console.log('Social videos stores err: ', error);
//                         selfs.setState({ loadingSocialData: false });
//                     })
//             }

//             if (photoList_details.length > 0) {
//                 let photoData = JSON.stringify({
//                     user_id: '',
//                     title: '',
//                     social_type: 'facebook',
//                     file_type: 'image',
//                     dataList: photoList_details
//                 });

//                 axios
//                     .post(`${apiUrl}/api/socialStorePhotos`, photoData, header)
//                     .then(response => {
//                         console.log('socialStorePhotos: ', response);
//                         viewPhotos(selfs);
//                     }, error => {
//                         console.log('Social photos stores err: ', error);
//                         selfs.setState({ loadingSocialData: false });
//                     })
//             }

//             if (videoList_details.length === 0 && photoList_details.length === 0) {
//                 viewPhotos(selfs);
//             }
//         }

//     }
// }
// function getFbPhoto(selfs, response) {
//     console.log("responses::", response)
//     if (response) {
//         let photoList = [];
//         let photoTmpList = [];
//         if (response.albums && response.albums.data) {
//             let albumList = response.albums.data ? response.albums.data : '';

//             //start hastag  ************************
//             console.log("albumList::", albumList)

//             let albumTmp = albumList.filter(alb => {
//                 if (alb.count > 0) {
//                     alb.photos.data.map(imgLine => {
//                         let str = imgLine.images[0].source ? imgLine.images[0].source : '';
//                         if (str.length > 0) {
//                             photoTmpList.push(str);
//                         }
//                     });
//                     return alb.photos.data;
//                 }
//             });
//             console.log("albumTmp::", albumTmp)

//             console.log("photoTmpList::", photoTmpList);
//             photoTmpList.forEach(oneImg => {
//                 if (oneImg) {
//                     photoList.push(oneImg);
//                 }
//             });

//             console.log("photoList::", photoList)
//             // photoTmpList.forEach(oneImg => {
//             //     photoList.push(oneImg.images[0].source);
//             // });

//             let header = {
//                 headers: {
//                     'Content-Type': 'application/json',
//                     Authorization: authHeader()
//                 }
//             };
//             let data = JSON.stringify({
//                 user_id: '',
//                 title: '',
//                 social_type: 'facebook',
//                 file_type: 'image',
//                 imageList: photoList
//             });

//             if (photoList.length > 0) {
//                 axios
//                     .post(`${apiUrl}/api/socialStorePhotos`, data, header)
//                     .then(response => {
//                         console.log('socialStorePhotos: ', response);
//                         viewPhotos(selfs);
//                     }, error => {
//                         console.log('Social photos stores err: ', error);
//                         selfs.setState({ loadingSocialData: false });
//                     })

//             } else {
//                 viewPhotos(selfs);
//             }

//         }//response.albums

//     }
// }

function viewPhotos(self) {
    let header = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader()
        }
    };
    self.setState({ loadingSocialData: true });
    let data = {};
    axios
        .post(`${apiUrl}/api/socialViewPhotos`, data, header)
        .then(response => {
            console.log("inside socialViewPhotos success", response.data.data.data);
            if (response.data.data.status === "success") {
                console.log("inside success socialViewPhotos", response.data.data.data);
                let imagesFBList = response.data.data.data.filter(item => item.filetype === "image" && item.social_type === "facebook");
                console.log("imagesFBList", imagesFBList);
                let videosFBList = response.data.data.data.filter(item => item.filetype === "video" && item.social_type === "facebook");
                console.log("videosFBList", videosFBList);
                let imagesIGList = response.data.data.data.filter(item => item.filetype === "image" && item.social_type === "instagram");
                console.log("imagesIGList", imagesIGList);
                let videosIGList = response.data.data.data.filter(item => item.filetype === "video" && item.social_type === "instagram");
                console.log("videosIGList", videosIGList);
                self.setState({ socialFBImagesList: imagesFBList, socialFBVideoList: videosFBList, socialIGImagesList: imagesIGList, socialIGVideoList: videosIGList, loadingSocialData: false });
            }
            else {
                self.setState({
                    socialFBImagesList: [],
                    socialFBVideoList: [],
                    socialIGImagesList: [],
                    socialIGVideoList: [],
                    loadingSocialData: false
                });
                console.log("inside else", response.data.data.data);
            }

        })
        .catch(function (error) {
            console.log("Inside error:", error.response);
            self.setState({ socialImageError: "Image not fetch from social!", loadingSocialData: false })

        });
}

function fetchInstagram(self, igsocialrequest, tag) {
    const header = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader()
        }
    };

    console.log("fetchInstagram", header);
    console.log("igsocialrequest", igsocialrequest);
    console.log("igsocialrequest tag", tag);

    // Define dimensions for the popup window
    const left = (window.screen.width / 2) - (window.screen.width / 5);
    const width = window.screen.width / 2.5;

    // Updated scopes for Instagram Business Login
    const clientId = '1124295486100940';
    const redirectUri = 'https://thisheart.co/InstagramCallback';

    const urlInsta = "https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=1124295486100940&redirect_uri=https://thisheart.co/InstagramCallback&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments%2Cinstagram_business_content_publish";
    console.log("urlInsta", urlInsta);

    const windowInstagram = window.open(urlInsta, "_instagram", `height=500,centerscreen=yes,top=50,width=${width},left=${left}`);
    let instagramAuthCode = "";

    // Polling the popup window for the authorization code
    const timer = setInterval(function () {
        if (windowInstagram.closed) {
            clearInterval(timer);
            try {
                // Extract authorization code from redirected URL
                const urlString = windowInstagram.document.URL;
                const responseUriCode = urlString ? new URL(urlString).searchParams.get('code') : '';
                instagramAuthCode = responseUriCode || '';
                console.log("instagramAuthCode", instagramAuthCode);

                if (instagramAuthCode) {
                    // Step 2: Exchange the Authorization Code for a Short-lived Token
                    if (windowInstagram) {
                        windowInstagram.close();
                    }
                    const body = {
                        client_id: clientId,
                        app_id: clientId,
                        app_secret: '87c807e7e8da01a67dfd981518fd0a4f',
                        grant_type: 'authorization_code',
                        redirect_uri: redirectUri,
                        code: instagramAuthCode
                    };

                    axios.post(`${apiUrl}/api/fetchInstagram`, body, header)
                        .then(response => {
                            const shortLivedToken = response.data.result.access_token;
                            const userId = response.data.result.user_id;
                            console.log("Access Token response:", response);
                            console.log("Short-Lived Access Token:", shortLivedToken);
                            // Step 4: Fetch User Media Data
                            const mediaUrl = `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,timestamp&access_token=${shortLivedToken}`;
                            axios.get(mediaUrl)
                                .then(response => {
                                    console.log("fetchInstagram media response", response);
                                    const mediaInstagram = response.data.data || [];
                                    let photoListInsta = mediaInstagram.filter(media => media.media_type === 'IMAGE');
                                    let videoListInsta = mediaInstagram.filter(media => media.media_type === 'VIDEO');
                                    console.log("photoListInsta", photoListInsta)
                                    console.log("videoListInsta", videoListInsta)

                                    let header = {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            Authorization: authHeader()
                                        }
                                    };

                                    if (igsocialrequest === 'photo') {
                                        if (tag) {
                                            let filteredPhotos = photoListInsta.filter(photo => {
                                                return photo.caption ? photo.caption.toLowerCase().split(' ').some(word => word.includes(tag.toLowerCase())) : true;
                                            });
                                            console.log("filteredPhotos", filteredPhotos)

                                            if (filteredPhotos.length > 0) {
                                                const photoData = JSON.stringify({
                                                    user_id: userId,
                                                    title: '',
                                                    social_type: 'instagram',
                                                    file_type: 'image',
                                                    dataList: filteredPhotos
                                                });
                                                console.log("photoData_with_tag", photoData)

                                                axios.post(`${apiUrl}/api/socialStorePhotos`, photoData, header)
                                                    .then(response => {
                                                        console.log('Instagram photos stored:', response);
                                                        viewPhotos(self);
                                                    })
                                                    .catch(error => {
                                                        console.log('Error storing Instagram photos:', error);
                                                        self.setState({ loadingSocialData: false });
                                                    });
                                            } else {
                                                ToastsStore.warning(`Tag ${tag} not found in photos.`);
                                            }
                                        } else {
                                            if (photoListInsta.length > 0) {
                                                const photoData = JSON.stringify({
                                                    user_id: userId,
                                                    title: '',
                                                    social_type: 'instagram',
                                                    file_type: 'image',
                                                    dataList: photoListInsta
                                                });
                                                console.log("photoData-without_tag", photoData)

                                                axios.post(`${apiUrl}/api/socialStorePhotos`, photoData, header)
                                                    .then(response => {
                                                        console.log('Instagram photos stored:', response);
                                                        viewPhotos(self);
                                                    })
                                                    .catch(error => {
                                                        console.log('Error storing Instagram photos:', error);
                                                        self.setState({ loadingSocialData: false });
                                                    });
                                            }
                                        }
                                    } else if (igsocialrequest === 'video') {
                                        if (tag) {
                                            let filteredVideos = videoListInsta.filter(video => {
                                                return video.caption ? video.caption.toLowerCase().split(' ').some(word => word.includes(tag.toLowerCase())) : true;
                                            });
                                            console.log("filteredVideos", filteredVideos)

                                            if (filteredVideos.length > 0) {
                                                const videoData = JSON.stringify({
                                                    user_id: userId,
                                                    title: '',
                                                    social_type: 'instagram',
                                                    file_type: 'video',
                                                    dataList: filteredVideos
                                                });
                                                console.log("videoData-wt", videoData)


                                                axios.post(`${apiUrl}/api/socialStorePhotos`, videoData, header)
                                                    .then(response => {
                                                        console.log('Instagram videos stored:', response);
                                                        viewPhotos(self);
                                                    })
                                                    .catch(error => {
                                                        console.log('Error storing Instagram videos:', error);
                                                        self.setState({ loadingSocialData: false });
                                                    });
                                            } else {
                                                ToastsStore.warning(`Tag ${tag} not found in videos.`);
                                            }
                                        } else {
                                            if (videoListInsta.length > 0) {
                                                const videoData = JSON.stringify({
                                                    user_id: userId,
                                                    title: '',
                                                    social_type: 'instagram',
                                                    file_type: 'video',
                                                    dataList: videoListInsta
                                                });
                                                console.log("videoData-wnt", videoData)

                                                axios.post(`${apiUrl}/api/socialStorePhotos`, videoData, header)
                                                    .then(response => {
                                                        console.log('Instagram videos stored:', response);
                                                        viewPhotos(self);
                                                    })
                                                    .catch(error => {
                                                        console.log('Error storing Instagram videos:', error);
                                                        self.setState({ loadingSocialData: false });
                                                    });
                                            }
                                        }
                                    }

                                    self.setState({ loadingSocialData: false });
                                })
                                .catch(error => {
                                    console.log('Error fetching Instagram media:', error);
                                    self.setState({ loadingSocialData: false });
                                });
                        })
                        .catch(error => {
                            console.log('Error exchanging code for token:', error);
                            self.setState({ loadingSocialData: false });
                        });
                }
            } catch (error) {
                console.log("Error processing Instagram authentication:", error);
                self.setState({
                    loadingSocialData: false,
                    socialImageError: "Failed to authenticate with Instagram."
                });
            }
        }
    }, 1000);
}
// function fetchInstagram(self, igsocialrequest, tag) {
//     const header = {
//         headers: {
//             'Content-Type': 'application/json',
//             Authorization: authHeader()
//         }
//     };

//     console.log("fetchInstagram", header);
//     console.log("igsocialrequest", igsocialrequest);
//     console.log("igsocialrequest tag", tag);

//     // Define dimensions for the popup window
//     const left = (window.screen.width / 2) - (window.screen.width / 5);
//     const width = window.screen.width / 2.5;

//     // Updated scopes for Instagram Business Login
//     const clientId = '1124295486100940';
//     const redirectUri = 'https://thisheart.co/InstagramCallback';

//     const urlInsta = "https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=1124295486100940&redirect_uri=https://thisheart.co/InstagramCallback&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments%2Cinstagram_business_content_publish";
//     console.log("urlInsta", urlInsta);

//     const windowInstagram = window.open(urlInsta, "_instagram", `height=500,centerscreen=yes,top=50,width=${width},left=${left}`);
//     let instagramAuthCode = "";

//     // Polling the popup window for the authorization code
//     const timer = setInterval(function () {
//         if (windowInstagram.closed) {
//             clearInterval(timer);
//             try {
//                 // Extract authorization code from redirected URL
//                 const urlString = windowInstagram.document.URL;
//                 const responseUriCode = urlString ? new URL(urlString).searchParams.get('code') : '';
//                 instagramAuthCode = responseUriCode || '';
//                 console.log("instagramAuthCode", instagramAuthCode);

//                 if (instagramAuthCode) {
//                     // Step 2: Exchange the Authorization Code for a Short-lived Token
//                     if (windowInstagram) {
//                         windowInstagram.close();
//                     }
//                     const body = {
//                         client_id: clientId,
//                         app_id: clientId,
//                         app_secret: '87c807e7e8da01a67dfd981518fd0a4f',
//                         grant_type: 'authorization_code',
//                         redirect_uri: redirectUri,
//                         code: instagramAuthCode
//                     };

//                     axios.post(`${apiUrl}/api/fetchInstagram`, body, header)
//                         .then(response => {
//                             const shortLivedToken = response.data.result.access_token;
//                             const userId = response.data.result.user_id;
//                             console.log("Access Token response:", response);
//                             console.log("Short-Lived Access Token:", shortLivedToken);
//                             // Step 4: Fetch User Media Data
//                             const mediaUrl = `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,timestamp&access_token=${shortLivedToken}`;
//                             axios.get(mediaUrl)
//                                 .then(response => {
//                                     console.log("fetchInstagram media response",response)
//                                     const imgInstagram = response.data.data || [];
//                                     const photoListInsta = imgInstagram.map(img => img.media_url);
//                                     console.log("imgInstagram", imgInstagram)

//                                     const data = JSON.stringify({
//                                         user_id: userId,
//                                         title: '',
//                                         social_type: 'instagram',
//                                         file_type: 'image',
//                                         imageList: photoListInsta
//                                     });

//                                     console.log("photoListInsta", photoListInsta);

//                                     // Step 5: Store Instagram Media Data in Your Server
//                                     if (photoListInsta.length > 0) {
//                                         axios.post(`${apiUrl}/api/socialStorePhotos`, data, header)
//                                             .then(response => {
//                                                 console.log('Social photos stored:', response);
//                                                 viewPhotos(self);
//                                             })
//                                             .catch(error => {
//                                                 console.log('Error storing social photos:', error);
//                                                 self.setState({ loadingSocialData: false });
//                                             });
//                                     } else {
//                                         viewPhotos(self);
//                                     }

//                                     self.setState({ loadingSocialData: false });
//                                 })
//                                 .catch(error => {
//                                     console.log('Error fetching Instagram media:', error);
//                                     self.setState({ loadingSocialData: false });
//                                 });
//                         })
//                         .catch(error => {
//                             console.log('Error exchanging code for token:', error);
//                             self.setState({ loadingSocialData: false });
//                         });
//                 }
//             } catch (error) {
//                 console.log("Error processing Instagram authentication:", error);
//                 self.setState({
//                     loadingSocialData: false,
//                     socialImageError: "Failed to authenticate with Instagram."
//                 });
//             }
//         }
//     }, 1000);
// }


// function fetchInstagram(self) {
//     let header = {
//         headers: {
//             'Content-Type': 'application/json',
//             Authorization: authHeader()
//         }
//     };
//     console.log("fetchInstagram", header);
//     var left = (window.screen.width / 2) - (window.screen.width / 5);
//     var width = window.screen.width / 2.5;
//     // var urlInsta = "https://api.instagram.com/oauth/authorize/?client_id=895231375405473&redirect_uri=https://thisheart.co/InstagramCallback&scope=user_profile,user_media&response_type=code";
//     // var urlInsta = "https://api.instagram.com/oauth/authorize/?client_id=1124295486100940&redirect_uri=https://thisheart.co/InstagramCallback&scope=user_profile,user_media&response_type=code";
//     var urlInsta = "https://www.instagram.com/oauth/authorize?enable_fb_login=1&force_authentication=0&client_id=1124295486100940&redirect_uri=https://thisheart.co/InstagramCallback&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments%2Cinstagram_business_content_publish";
//     console.log("urlInsta", urlInsta);
//     var windowInstagram = window.open(urlInsta, "_instagram", "height=500,centerscreen=yes,top=50,width=" + width + ",left=" + left);
//     var responseUriCode = "";
//     var instagramAuthCode = "";

//     var timer = setInterval(function () {
//         if (windowInstagram.closed) {
//             try {
//                 console.log("windowInstagram", windowInstagram);
//                 let urlString = windowInstagram.document.URL;
//                 responseUriCode = urlString.length > 0 ? urlString.split("?code=")[1] : '';
//                 var stringLen = responseUriCode ? responseUriCode.length - 2 : '';
//                 instagramAuthCode = stringLen > 0 ? responseUriCode.substr(0, stringLen) : '';
//                 console.log("instagramAuthCode", instagramAuthCode);
//                 if (instagramAuthCode) {
//                     windowInstagram.close();
//                     var body = {
//                         client_id: '1124295486100940',
//                         app_id: '1124295486100940',
//                         app_secret: '87c807e7e8da01a67dfd981518fd0a4f',
//                         // let photoListInsta = []
//                         //                 imgInstagram.map((img, i) => {
//                         //                     photoListInsta.push(img.media_url)
//                         //                 })
//                         grant_type: 'authorization_code',
//                         redirect_uri: 'https://thisheart.co/InstagramCallback',
//                         code: instagramAuthCode
//                     };
//                     console.log("body", body);

//                     axios
//                         .post(`${apiUrl}/api/fetchInstagram`, body, header)
//                         .then(response => {
//                             console.log("fetchInstagram 2", response);
//                             if (response.data.status === "success") {
//                                 console.log("fetchInstagram 3", response.data);
//                                 var instagram_access_token = response.data.result.access_token;
//                                 var media_url = "https://graph.instagram.com/me/media?fields=id,caption,media_url&access_token=" + instagram_access_token;
//                                 axios.get(media_url).then(
//                                     response => {
//                                         let imgInstagram = response.data.data ? response.data.data : '';
//                                         // let photoListInsta = []
//                                         // imgInstagram.map((img, i) => {
//                                         //     photoListInsta.push(img.media_url)
//                                         // })
//                                         let photoListInsta = [];
//                                         imgInstagram.forEach((img) => {
//                                             photoListInsta.push(img.media_url);
//                                         });

//                                         let data = JSON.stringify({
//                                             user_id: '',
//                                             title: '',
//                                             social_type: 'instagram',
//                                             file_type: 'image',
//                                             imageList: photoListInsta
//                                         });
//                                         console.log("photoListInsta", photoListInsta);
//                                         if (photoListInsta.length > 0) {
//                                             axios
//                                                 .post(`${apiUrl}/api/socialStorePhotos`, data, header)
//                                                 .then(response => {
//                                                     console.log('Social photos stores: ', response);
//                                                     viewPhotos(self);
//                                                 }, error => {
//                                                     console.log('Social photos stores: ', error);
//                                                     self.setState({ loadingSocialData: false });
//                                                 })
//                                         } else {
//                                             viewPhotos(self);
//                                         }
//                                         self.setState({
//                                             loadingSocialData: false
//                                         });
//                                     }
//                                 )
//                             }
//                             else {
//                                 console.log("fetchInstagram 4", response.data);
//                                 self.setState({
//                                     loadingSocialData: false
//                                 });
//                             }
//                         }, error => {
//                             console.log(error)
//                             self.setState({
//                                 loadingSocialData: false
//                             });
//                         });
//                 }
//             } catch (error) {
//                 console.log("error", error);
//                 self.setState({
//                     loadingSocialData: false
//                 });
//             }
//         } else {
//             clearInterval(timer);
//             // Handle the popup being blocked or closed early
//             self.setState({
//                 loadingSocialData: false,
//                 socialImageError: "Failed to authenticate with Instagram."
//             });
//         }
//     }, 1000);
// }
// function fetchInstagram(self) {
//     let header = {
//         headers: {
//             'Content-Type': 'application/json',
//             Authorization: authHeader()
//         }
//     };
//     console.log("fetchInstagram", header);
//     var left = (window.screen.width / 2) - (window.screen.width / 5);
//     var width = window.screen.width / 2.5;
//     // var urlInsta = "https://api.instagram.com/oauth/authorize/?client_id=895231375405473&redirect_uri=https://thisheart.co/InstagramCallback&scope=user_profile,user_media&response_type=code";
//     var urlInsta = "https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=1124295486100940&redirect_uri=https://thisheart.co/InstagramCallback&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments%2Cinstagram_business_content_publish";
//     console.log("urlInsta", urlInsta);
//     var windowInstagram = window.open(urlInsta, "_instagram", "height=500,centerscreen=yes,top=50,width=" + width + ",left=" + left);
//     var responseUriCode = "";
//     var instagramAuthCode = "";

//     var timer = setInterval(function () {
//         if (windowInstagram.closed) {
//             try {
//                 console.log("windowInstagram", windowInstagram);
//                 let urlString = windowInstagram.document.URL
//                 responseUriCode = urlString.length > 0 ? urlString.split("?code=")[1] : '';
//                 var stringLen = responseUriCode ? responseUriCode.length - 2 : '';
//                 instagramAuthCode = stringLen > 0 ? responseUriCode.substr(0, stringLen) : '';
//                 console.log("instagramAuthCode", instagramAuthCode);
//                 if (instagramAuthCode) {
//                     windowInstagram.close();
//                     var body = {
//                         client_id: '1124295486100940',
//                         app_id: '1124295486100940',
//                         app_secret: '87c807e7e8da01a67dfd981518fd0a4f',
//                     // var body = {
//                     //     client_id: '895231375405473',
//                     //     app_id: '895231375405473',
//                     //     app_secret: '2553bbf0702fc511e29bba85622bd28b',
//                         grant_type: 'authorization_code',
//                         redirect_uri: 'https://thisheart.co/InstagramCallback',
//                         code: instagramAuthCode
//                     }
//                     console.log("body", body);

//                     axios
//                         .post(`${apiUrl}/api/fetchInstagram`, body, header)
//                         .then(response => {
//                             console.log("fetchInstagram 2", response);
//                             if (response.data.status === "success") {
//                                 console.log("fetchInstagram 3", response.data);
//                                 var instagram_access_token = response.data.result.access_token;
//                                 var media_url = "https://graph.instagram.com/me/media?fields=id,caption,media_url&access_token=" + instagram_access_token;
//                                 axios.get(media_url).then(
//                                     response => {
//                                         let imgInstagram = response.data.data ? response.data.data : '';
//                                         let photoListInsta = []
//                                         imgInstagram.map((img, i) => {
//                                             photoListInsta.push(img.media_url)
//                                         })

//                                         let data = JSON.stringify({
//                                             user_id: '',
//                                             title: '',
//                                             social_type: 'instagram',
//                                             file_type: 'image',
//                                             imageList: photoListInsta
//                                         });
//                                         console.log("photoListInsta", photoListInsta);
//                                         if (photoListInsta.length > 0) {
//                                             axios
//                                                 .post(`${apiUrl}/api/socialStorePhotos`, data, header)
//                                                 .then(response => {
//                                                     console.log('Social photos stores: ', response);
//                                                     viewPhotos(self);
//                                                 }, error => {
//                                                     console.log('Social photos stores: ', error);
//                                                     self.setState({ loadingSocialData: false });
//                                                 })
//                                         } else {
//                                             viewPhotos(self);
//                                         }
//                                         self.setState({
//                                             loadingSocialData: false
//                                         });
//                                     }
//                                 )
//                             }
//                             else {
//                                 console.log("fetchInstagram 4", response.data);
//                                 self.setState({
//                                     loadingSocialData: false
//                                 });
//                             }
//                             // self.setState({
//                             //     loadingSocialData: false
//                             // });
//                         }, error => {
//                             console.log(error)
//                             self.setState({
//                                 loadingSocialData: false
//                             });
//                         });
//                 }
//             } catch (error) {
//                 console.log("error", error);
//                 self.setState({
//                     loadingSocialData: false
//                 });
//             }
//         } else {
//             clearInterval(timer);
//             // Handle the popup being blocked or closed early
//             self.setState({
//                 loadingSocialData: false,
//                 socialImageError: "Failed to authenticate with Instagram."
//             });
//         }
//     }, 1000);

// }




async function fetchTwitter(self) {
    const oauthBaseURL = "https://api.x.com";
    const apiUrl = "https://admin.thisheart.co";
    let oauthToken, oauthTokenSecret;

    // Step 1: Get Request Token
    try {
        const response = await axios.post(`${oauthBaseURL}/oauth/request_token`, null, {
            headers: {
                Authorization: `OAuth oauth_callback="oob"`,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
        const params = new URLSearchParams(response.data);
        oauthToken = params.get("oauth_token");
        oauthTokenSecret = params.get("oauth_token_secret");

        // Step 2: Redirect User for Authorization
        const authURL = `${oauthBaseURL}/oauth/authorize?oauth_token=${oauthToken}`;
        const authWindow = window.open(authURL, "_blank");

        const verifierCode = await waitForUserInput(authWindow);

        // Step 3: Exchange Request Token for Access Token
        const accessTokenResponse = await axios.post(`${oauthBaseURL}/oauth/access_token`, null, {
            headers: {
                Authorization: `OAuth oauth_token=${oauthToken}, oauth_verifier=${verifierCode}`,
            },
        });

        const accessParams = new URLSearchParams(accessTokenResponse.data);
        oauthToken = accessParams.get("oauth_token");
        oauthTokenSecret = accessParams.get("oauth_token_secret");

        // Step 4: Fetch User's Posts (Tweets)
        const tweetsResponse = await axios.get(`${oauthBaseURL}/1.1/statuses/user_timeline.json`, {
            params: {
                count: 50, // Fetch 50 recent tweets
                include_entities: true,
            },
            headers: {
                Authorization: `OAuth oauth_token=${oauthToken}, oauth_token_secret=${oauthTokenSecret}`,
            },
        });

        const tweets = tweetsResponse.data;

        // Step 5: Extract Photos from Tweets
        const photoListTwitter = [];
        tweets.forEach((tweet) => {
            if (tweet.entities && tweet.entities.media) {
                tweet.entities.media.forEach((media) => {
                    if (media.type === "photo") {
                        photoListTwitter.push(media.media_url);
                    }
                });
            }
        });

        // Step 6: Send Photos to Backend for Storage
        if (photoListTwitter.length > 0) {
            const data = {
                user_id: "", // Replace with the user ID
                social_type: "twitter",
                file_type: "image",
                imageList: photoListTwitter,
            };

            await axios.post(`${apiUrl}/api/socialStorePhotos`, data, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            console.log("Photos saved successfully!");
        } else {
            console.log("No photos found in the tweets.");
        }
    } catch (error) {
        console.error("Error during Twitter authentication or photo retrieval:", error);
    }
}

// Helper function to wait for user input in the authorization window
function waitForUserInput(authWindow) {
    return new Promise((resolve, reject) => {
        const timer = setInterval(() => {
            try {
                if (authWindow.closed) {
                    clearInterval(timer);
                    reject(new Error("Authorization window closed by user."));
                }

                const url = authWindow.location.href;
                if (url.includes("oauth_verifier")) {
                    const params = new URLSearchParams(url.split("?")[1]);
                    const verifierCode = params.get("oauth_verifier");
                    authWindow.close();
                    clearInterval(timer);
                    resolve(verifierCode);
                }
            } catch (err) {
                // Ignore cross-origin errors while waiting
            }
        }, 1000);
    });
}

// function fetchTwitter(self) {
//     let header = {
//         headers: {
//             'Content-Type': 'application/json',
//             //Authorization: authHeader()
//         }
//     };

//     let codebird = new Codebird();
//     //codebird.setProxy("https://admin.thisheart.co/codebird-cors-proxy/");
//     codebird.setConsumerKey('qIH4ZysqwBxEz1UOW7iKmN8TA', '1KiTbA9XwbIikFmGz9u0xIPbN9jZabTNNcUUqkFRJfmVnHwkI9');
//     codebird.setToken('1752293980354887680-5BJMYNIQ9IvR35K9IyreurkLTarlB7', 'hlIaiGAcM6CqtMV7bh4UpVqkvu8vyakkrg4zhKfjzn2RY');

//     //Bearer token = AAAAAAAAAAAAAAAAAAAAAFCesAEAAAAAJ3YFvn8TtWq3R89bHKd%2F%2Bz2WwAA%3D6JL6sE8vTw4WB1b51pWrG2eZjLn0Pd1V21vvQRBLrQ4XHSGK3V
//     //client-id = aW1EeXdiaFM3dkEzY3VmTDBxa1k6MTpjaQ   
//     //client secret = cVugwPDfT1xWydQOASYrZKe7UJT7d7tm6ESn2ep7Cp3Mi9QTHE
//     //access token = 1752293980354887680-5BJMYNIQ9IvR35K9IyreurkLTarlB7
//     //access token secret = hlIaiGAcM6CqtMV7bh4UpVqkvu8vyakkrg4zhKfjzn2RY

//     // var params = {
//     //   q : "#shahin",
//     //   result_type : "recent",
//     //   include_entities : "true"
//     // }



//     // gets a request token
//     codebird.__call(
//         "oauth_requestToken",
//         { oauth_callback: "oob" },
//         function (reply) {
//             console.log('reply :::', reply);
//             // stores it
//             codebird.setToken(reply.oauth_token, reply.oauth_token_secret);

//             // gets the authorize screen URL
//             codebird.__call(
//                 "oauth_authorize",
//                 {},
//                 function (auth_url) {
//                     window.codebird_auth = window.open(auth_url);
//                 }
//             );
//         }
//     );





//     //gets a request token
//     codebird.__call("oauth/request_token", {}, function (
//         reply,
//         rate,
//         err
//     ) {
//         if (err) {
//             console.log("error response or timeout exceeded 394::" + err.error);
//         }
//         if (reply) {
//             if (reply.errors && reply.errors["415"]) {
//                 console.log(reply.errors["415"]);
//                 return;
//             }
//             console.log("Reply-406::", reply);

//             // stores the token
//             codebird.setToken(reply.oauth_token, reply.oauth_token_secret);
//             var left = (window.screen.width / 2) - (window.screen.width / 5);
//             var width = window.screen.width / 2.5;

//             var urlTwitter = "https://api.twitter.com/oauth/authorize?oauth_token=" + reply.oauth_token;
//             console.log("url-twitter::-", urlTwitter);
//             var windowTwitter = window.open(urlTwitter, "_twitter", "height=500,centerscreen=yes,top=50,width=" + width + ",left=" + left);
//             var responseUriCode = "";
//             var twitterVerifierCode = "";

//             var timer = setInterval(function () {
//                 if (windowTwitter && windowTwitter.closed) {
//                     clearInterval(timer);
//                     console.log("windowTwitter", windowTwitter);
//                     try {
//                         let urlString = windowTwitter.document.URL
//                         responseUriCode = urlString.length > 0 ? urlString.split("&oauth_verifier=")[1] : '';
//                         var stringLen = responseUriCode ? responseUriCode.length : '';
//                         twitterVerifierCode = stringLen > 0 ? responseUriCode.substr(0, stringLen) : '';
//                         console.log("twitterVerifierCode", twitterVerifierCode);
//                         if (twitterVerifierCode) {
//                             windowTwitter.close();
//                             codebird.__call(
//                                 "oauth/accessToken",
//                                 {
//                                     oauth_verifier: twitterVerifierCode
//                                 },
//                                 console.log("twitterVerifierCode", twitterVerifierCode),
//                                 function (reply, rate, err) {
//                                     if (err) {
//                                         console.log("error response or timeout exceeded" + err.error);
//                                     }
//                                     if (reply) {
//                                         codebird.setToken(reply.oauth_token, reply.oauth_token_secret);
//                                         codebird.__call("statuses/user_timeline", {}, function (
//                                             tweets,
//                                             rate,
//                                             err
//                                         ) {
//                                             if (err) {
//                                                 console.log("error response or timeout exceeded" + err.error);
//                                             }
//                                             if (tweets) {
//                                                 if (tweets.length > 0) {
//                                                     let photoListTwitter = []
//                                                     console.log("tweets", tweets);
//                                                     tweets.map((twt) => {
//                                                         let twtMedia = twt.entities.media ? twt.entities.media : ''
//                                                         if (twtMedia.length > 0) {
//                                                             photoListTwitter.push(twtMedia[0].media_url)
//                                                         }
//                                                     });//end map

//                                                     let data = JSON.stringify({
//                                                         user_id: '',
//                                                         title: '',
//                                                         social_type: 'twitter',
//                                                         file_type: 'image',
//                                                         imageList: photoListTwitter
//                                                     });

//                                                     if (photoListTwitter.length > 0) {
//                                                         axios
//                                                             .post(`${apiUrl}/api/socialStorePhotos`, data, header)
//                                                             .then(response => {
//                                                                 viewPhotos(self);
//                                                             }, error => {
//                                                                 self.setState({ loadingSocialData: false });
//                                                             })
//                                                     } else {
//                                                         viewPhotos(self);
//                                                     }
//                                                     self.setState({
//                                                         loadingSocialData: false
//                                                     });
//                                                 }//end if(tweets.length>0)
//                                             }
//                                         });
//                                     }
//                                 }
//                             );

//                         }
//                     } catch (error) {
//                         console.log(error)
//                         self.setState({
//                             loadingSocialData: false
//                         });
//                     }
//                 } else if (!windowTwitter) {
//                     clearInterval(timer);
//                     console.log("Twitter window was not opened or was blocked by a popup blocker.");
//                     self.setState({
//                         loadingSocialData: false,
//                         socialImageError: "Failed to authenticate with Twitter."
//                     });
//                 }
//             }, 1000);

//         }
//         else {
//             console.log("error connecting to twitter" + err);
//         }
//     });



// }


export default socialService;
