import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import apiService from "./../services/api.service";
import { authHeader } from '../helpers/auth-header';



// Initialize Stripe with your publishable key
const stripePromise = loadStripe("pk_test_9muBAZnjHeFvBwxhQO8k145F00GXiohNqD");

const CheckoutButton = () => {

  const apiUrl = apiService.apiUrl;
  const handleCheckout = async () => {
    const stripe = await stripePromise;

    try {
         let header = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: authHeader()
            }
        };
      // Call your backend to create the checkout session
      const response = await axios.post("http://localhost:8000/api/create-checkout-session",{}, header);

      // Redirect to Stripe Checkout
      console.log('url:::', response.data)
      await stripe.redirectToCheckout({ sessionId: response.data.id });
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  return (
    <button onClick={handleCheckout}>
      Subscribe for $10/month
    </button>
  );
};

export default CheckoutButton;
