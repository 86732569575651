import { authHeader } from '../helpers/auth-header';
import axios from 'axios';
import apiService from './api.service';


 const  PackagesServices = {
    getPackages,
    savePackageInfo,
    savePaymentInfo,
    getPackageByUser,
    userPackageDays,
    unsubscribePackage,
};

    const apiUrl = apiService.apiUrl;

function getPackages(self){
    let header = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader()
        }
    };
    return axios
        .post(`${apiUrl}/api/getPackages`, {},header);
}

function getPackageByUser(self){
    if(localStorage.getItem('user_type')==="beneficiary"){
        return false;
      }
    let header = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader()
        }
    };
    return axios
        .post(`${apiUrl}/api/getPackageByUser`, {},header);
}

function userPackageDays(self){
    let package_info = this.getPackageByUser(self);
    return package_info;
}

function savePackageInfo(self,data){
     
    let header = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader()
        }
    };
    return axios
        .post(`${apiUrl}/api/savePackageInfo`, data, header);
}

function unsubscribePackage(self){
    let header = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader()
        }
    };
 
    return axios
        .post(`${apiUrl}/api/unsubscribePackage`, {}, header);
}

function savePaymentInfo(self,data){
    let header = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader()
        }
    };
    return axios
        .post(`${apiUrl}/api/savePaymentInfo`, data, header);
}


export default PackagesServices;
